.QuickMsg {
  position: absolute;
  bottom: -53px;
  left: calc(100% + 1px);
  width: 636px;
  z-index: 99;
}

.Styles.Popover {
  padding: 8px;
  font-size: 13px;
}

.ContentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition-duration: 0.2s;
  border-radius: 6px 6px 0px 0px;
}
.ContentContainer .Header {
  display: flex;
  height: 34px;
  background-color: #6c757d;
  border-radius: 6px 6px 0px 0px;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
}
.ContentContainer .Header span {
  font-size: 15px;
}
.ContentContainer .Header .addButton {
  font-size: 14px;
  color: #fff !important;
}
.ContentContainer .Body {
  display: block;
  flex: 4 1 0;
  overflow-y: auto;
  position: relative;
  min-height: 0;
  background-color: #ffffff;
  border: 1px solid var(--qt-divider);
}
.ContentContainer .Body .ScrollContainer {
  overflow-x: hidden;
}
.ContentContainer .Body .MsgContainer {
  cursor: pointer;
  padding-inline: 8px;
  padding-block: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ContentContainer .Body .MsgContainer:not(:last-of-type) {
  border-bottom: #eef5f9 solid 1px;
}
.ContentContainer .Body .MsgContainer .MsgTitle {
  font-size: 13px;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ContentContainer .Body .MsgContainer .MsgTitle::before {
  content: "#";
}
.ContentContainer .Body .MsgContainer .MsgContent {
  font-weight: 500;
  flex: 1;
}
.ContentContainer .Body .MsgContainer .MsgContent p {
  font-size: 13px;
  width: 100%;
  color: #6c757d;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
  line-height: 1.2;
}
.ContentContainer .Body .NoMsg {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  color: #6c757d;
}
.ContentContainer .Body .NoMsg p {
  font-weight: 700;
  font-size: 14px;
  margin: 0;
}
.ContentContainer .Body .NoMsg button {
  margin-top: 10px;
}