.DateLine {
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
}
.DateLine::before, .DateLine::after {
  content: "";
  display: block;
  flex: 1;
  height: 1px;
  background-color: var(--qt-gray5);
}
.DateLine::before {
  margin-right: 0.5rem;
}
.DateLine::after {
  margin-left: 0.5rem;
}
.DateLine .Message {
  font-size: 12px;
  font-weight: 500;
  color: var(--qt-gray7);
  text-align: center;
}