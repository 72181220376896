.Header{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: #FFFFFF;

  .Label{
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 0;
    color:var(--bs-gray-900)
  }
  .NotUseButton{
    display: flex;
    flex-direction: column;
    width: 1.625rem;
    align-items: center;
    justify-content: center;

  }
  .UseButton {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}