.Maintenance {
  background-color: #f7f7f7;
  flex: 1 1 0;
  font-family: 'Noto Sans KR', sans-serif;
  color: #222;

  .Title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.44;
    letter-spacing: -0.9px;
  }

  .Footer {
    font-size: 14px;
    margin-top: 16px;
    line-height: 1.57;
    letter-spacing: -0.49px;
    color: #767676;
  }
}