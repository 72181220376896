.search_radio {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  margin-right: 10px;
  border-radius: 8px;
  border: 1px solid #c3c8cc;
  background-color: #fff;
  min-width: 150px;
}
.search_radio div:first-child {
  margin-right: 15px;
}
.search_radio input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.search_radio input[type=radio]:checked + .input_radio {
  border: 1px solid #0060cb;
  background-color: #7dc8ff;
}
.search_radio input[type=radio]:checked + .input_radio::after {
  transform: scale(1);
}
.search_radio input[type=radio]:hover + .input_radio {
  border: 1px solid #4ca0fe;
}
.search_radio input[type=radio]:disabled + .input_radio {
  background-color: #f7f7f7;
  border: 1px solid #e8e8e8;
}
.search_radio input[type=radio]:disabled + .input_radio:after {
  background-color: #d9d9d9;
}
.search_radio input[type=radio]:focus + .input_radio {
  border: 1px solid #4ca0fe;
}

.radio_area {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.input_radio {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 15px;
  aspect-ratio: 1;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 50%;
}
.input_radio::after {
  content: "";
  position: absolute;
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #fff;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.radio_label {
  user-select: none;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding-left: 20px;
  cursor: pointer;
  flex-shrink: 0;
  /* Body 1 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 400;
}