.search_radio {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  margin-right: 10px;
  border-radius: 8px;
  border: 1px solid #c3c8cc;
  background-color: #fff;
  min-width: 150px;
}
.search_radio div:first-child {
  margin-right: 15px;
}
.search_radio input[type=radio] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
}
.search_radio input[type=radio]:checked + .input_radio {
  border: 1px solid #0060cb;
  background-color: #7dc8ff;
}
.search_radio input[type=radio]:checked + .input_radio::after {
  transform: scale(1);
}
.search_radio input[type=radio]:hover + .input_radio {
  border: 1px solid #4ca0fe;
}
.search_radio input[type=radio]:disabled + .input_radio {
  background-color: #f7f7f7;
  border: 1px solid #e8e8e8;
}
.search_radio input[type=radio]:disabled + .input_radio:after {
  background-color: #d9d9d9;
}
.search_radio input[type=radio]:focus + .input_radio {
  border: 1px solid #4ca0fe;
}

.radio_area {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.input_radio {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 15px;
  aspect-ratio: 1;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  border-radius: 50%;
}
.input_radio::after {
  content: "";
  position: absolute;
  width: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #fff;
  transform: scale(0);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.radio_label {
  user-select: none;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  padding-left: 20px;
  cursor: pointer;
  flex-shrink: 0;
  /* Body 1 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 400;
}

.search_radio {
  height: 34px;
  gap: 15px;
}
.search_radio div:first-child {
  margin-right: 0;
}
.search_radio input {
  color: #000;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #c3c8cc;
  margin: 0px;
  white-space: inherit;
  flex: auto;
  width: auto;
  min-width: auto;
  padding: 0px;
  border-radius: 4px;
}
.search_radio input:hover {
  border: 1px solid #4ca0fe;
  background: #fff;
}
.search_radio input:active {
  border: 1px solid #0078fe;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}
.search_radio input:focus {
  outline: none;
  box-shadow: none;
  caret-color: #0078fe;
}
.search_radio:hover {
  border: 1px solid #4ca0fe;
  background: #fff;
}
.search_radio:active {
  border: 1px solid #0078fe;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}
.search_radio:focus, .search_radio:focus-within, .search_radio:focus-visible {
  background: #fff !important;
  border: 1px solid #4ca0fe !important;
  box-shadow: 0px 0px 0px 1px #7dc8ff !important;
  outline: none !important;
}