.QuickButtonWrap {
  display: flex;
  align-items: center;
  padding: 0 16px 6px;
  position: absolute;
  bottom: 100%;
  left: 0;
}
.QuickButtonWrap :global .ant-tag {
  cursor: pointer;
}

.MessageInput {
  width: 100%;
  position: relative;
  background-color: var(--qt-gray1);
  padding-block: 6px;
  padding-left: 14px;
  color: var(--qt-ch-primary);
  border-top: 1px solid var(--qt-divider);
  display: flex;
  flex-direction: column;
}
.MessageInput.isError {
  outline: 1px solid var(--qt-ch-danger);
}
.MessageInput .MessageInputContent {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 4px;
}
.MessageInput .ButtonWrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MessageInput .OptionOpener {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  aspect-ratio: 1;
}
.MessageInput .OptionOpener .Button {
  display: flex;
  padding: 0;
  font-size: 2rem;
  transition: 0.5s ease-in-out;
  animation-duration: 1s;
}
.MessageInput .TextArea {
  flex: 1;
  min-width: 0;
  width: 100%;
  resize: none;
  max-height: 136px;
  font-size: 14px;
  padding: 8px 46px 8px 4px;
  border-radius: 0;
  outline-color: transparent;
  border: 0 !important;
  color: var(--qt-ch-primary);
}
.MessageInput .TextArea::placeholder {
  color: var(--qt-ch-disabled-placeholder);
}
.MessageInput .TextArea:focus-visible {
  outline: none;
}
.MessageInput .SendButton {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 14px;
}
.MessageInput .Option {
  transition-duration: 0.2s;
  overflow: hidden;
  display: flex;
}
.MessageInput .Option .OptionWrap {
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 4px;
}
.MessageInput .Option .OptionWrap:hover {
  background-color: var(--bs-gray-200);
}
.MessageInput .Option .OptionWrap .Icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 2rem;
  border-radius: 3rem;
  border: 0;
}
.MessageInput .ReplyMark {
  font-size: 1.5rem;
  color: #4b4b4b;
  z-index: 1;
}

.UploadedFile {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  top: 0;
  transform: translateY(-100%);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
}
.UploadedFile .ImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--qt-cool-gray6);
  padding: 12px 14px;
}
.UploadedFile .ImageContainer .Image {
  max-height: 110px;
  max-width: 80%;
  height: auto;
  position: relative;
  width: auto;
  display: flex;
  justify-content: center;
  align-content: center;
}

.Reply {
  background-color: var(--qt-cool-gray5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  gap: 4px;
}
.Reply .FileTextWrap {
  display: flex;
  align-items: center;
}
.Reply .Content {
  color: var(--qt-ch-primary-inverse);
  font-size: 12px;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Reply .Content.FileText {
  display: flex;
  align-items: center;
  gap: 4px;
}
.Reply .Name {
  font-size: 12px;
  font-weight: 500;
  color: var(--qt-primary3);
}
.Reply .Clear {
  min-width: 20px;
  min-height: 20px;
  cursor: pointer;
  color: var(--qt-ch-primary-inverse);
}