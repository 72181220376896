.Bubble {
  background-color: var(--qt-cool-gray1);
  border-radius: 4px;
  font-size: 13.5px;
  position: relative;
}
.Bubble.In {
  background-color: var(--qt-cool-gray1);
  color: var(--qt-cool-gray7);
}
.Bubble.In::after {
  border-top: 6px solid var(--qt-cool-gray1);
  border-bottom: 6px solid transparent;
  border-right: 6px solid var(--qt-cool-gray1);
  border-left: 6px solid transparent;
  left: -6px;
}
.Bubble.Out {
  background-color: var(--qt-cool-gray7);
  color: var(--qt-ch-primary-inverse);
}
.Bubble.Out::after {
  border-top: 6px solid var(--qt-cool-gray7);
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
  border-left: 6px solid var(--qt-cool-gray7);
  right: -6px;
}
.Bubble::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
}

.FileContent {
  padding: 8px 10px;
}
.FileContent .FileDownloadButton {
  font-size: 13px;
  display: flex;
  cursor: pointer;
}
.FileContent .FileDownloadButton .FileName {
  font-size: 13px;
}
.FileContent .FileDownloadButton .FileSize {
  text-align: right;
  font-size: 12px;
  color: #adadad;
}
.FileContent .FileDownloadButton .DownloadIcon {
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--bs-info);
  color: var(--qt-cool-gray1);
  padding: 5px;
}

.TextContent {
  padding: 8px 10px;
  white-space: pre-wrap;
  word-break: break-word;
}
.TextContent .Highlight {
  color: var(--qt-primary6);
  background-color: var(--qt-primary2);
}

.ImageContent {
  padding: 10px;
  display: flex;
}
.ImageContent img {
  max-height: 120px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.ImageContent.First {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.ImageContent.Last {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.ButtonGroupContent.Normal {
  display: flex;
  flex-direction: column;
}
.ButtonGroupContent.Normal .Button {
  border-radius: 0;
}
.ButtonGroupContent.Normal .Button.First {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.ButtonGroupContent.Normal .Button.Last {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.ButtonGroupContent.Grid {
  margin-bottom: 0.5rem;
  column-gap: 8px;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 8px;
  min-width: 320px;
}
.ButtonGroupContent.Grid .Button {
  -webkit-user-drag: none;
  user-select: none;
  color: var(--qt-cool-gray7);
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  align-items: center;
  background-color: var(--qt-cool-gray1);
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid var(--gray-200, #eef5f9);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 83px;
  justify-content: center;
  text-decoration: none;
  font-size: 0.8125rem;
  font-weight: 500;
  letter-spacing: -0.46px;
  overflow: hidden;
}
.ButtonGroupContent.Grid .Button:active {
  background-color: #cccccc;
}
.ButtonGroupContent.Grid .Button svg {
  margin-bottom: 0.3rem;
}
.ButtonGroupContent.Grid .Button img {
  margin-bottom: 0.3rem;
}

.CustomContent {
  margin-bottom: 0.5rem;
}
.CustomContent .Bubble.In::after {
  display: none;
}
.CustomContent .Bubble.Out:active {
  display: none;
}

.QuickReplies {
  margin-top: 16px;
  display: flex;
}
.QuickReplies .QuickReply {
  font-weight: 500;
  font-size: 13.5px;
  padding: 8px 10px;
  border-radius: 100px;
  border: solid 1px var(--gray-400, #ced4da);
  background-color: var(--qt-cool-gray1);
  color: var(--qt-cool-gray7);
  margin-right: 6px;
  margin-bottom: 4px;
}
.QuickReplies .QuickReply.point {
  border: solid 1px #e57355;
  color: #e57355;
}
.QuickReplies .QuickReply:active {
  background-color: #cccccc;
}