.Header {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 8px 14px;
  border-bottom: 1px solid var(--qt-divider);
  background: var(--qt-gray1);
}
.Header .Left {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  gap: 8px;
}
.Header .Left .Avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 1rem;
  overflow: hidden;
}
.Header .Left .Avatar img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.Header .Left .Sender {
  color: var(--qt-ch-primary);
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Header .Right {
  display: flex;
  justify-content: flex-end;
  gap: 6px;
}
.Header .Right .RequestButton {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .SearchHeader {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 56px;
  padding-inline: 14px;
  border-bottom: 1px solid var(--qt-divider);
  transition: all ease-in 0.25s;
  transform: translateY(-100%);
  background: var(--qt-gray1);
}
.Header .SearchHeader.IsOpen {
  transform: translateY(0);
}
.Header .SearchHeader .Search {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Header .SearchHeader .Input {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0;
}
.Header .SearchHeader .Input input {
  outline-color: transparent;
  font-size: 14px;
  border: 0 !important;
  color: var(--qt-ch-primary);
  padding-inline: 10px;
}
.Header .SearchHeader .Input input::placeholder {
  color: var(--qt-cool-gray4);
}
.Header .SearchHeader .Input input:focus {
  outline: none;
  box-shadow: none;
}
.Header .SearchHeader .Input input:focus-visible {
  outline: none;
  box-shadow: none;
}
.Header .SearchHeader .Cancel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-color: var(--qt-gray5);
  border-radius: 4px;
  padding: 2px 12px;
  font-size: 14px;
  color: var(--qt-ch-primary);
}
.Header .SearchHeader .Cancel:hover {
  background-color: var(--qt-bg-hover);
}