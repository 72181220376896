.BackDrop {
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DeleteBtnArea {
  z-index: -1;
  width: 70px;
  height: 31px;
}

.ExcelUploadArea {
  z-index: -1;
  width: 107px;
  height: 31px;
}

.ExcelIcon {
  color: var(--bs-success);
}
.ExcelIcon:hover {
  color: var(--bs-white);
}

.CheckBox {
  color: #212121;
  text-align: center;
}

.Header {
  background: #fff;
  color: #212121;
  font-size: 0.875rem;
}

.Cell {
  border: 0;
  color: #212121;
  vertical-align: middle;
}
.Cell .Button {
  cursor: pointer;
}
.Cell > div > div {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Create td {
  border-top: 1px solid #71ca2c !important;
  border-color: #71ca2c !important;
}

.Delete td {
  border-top: 1px solid #fe1515 !important;
  border-color: #fe1515 !important;
}

.Modify td {
  border-top: 1px solid #fe9315 !important;
  border-color: #fe9315 !important;
}

.Disabled::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.Disabled td {
  color: #666;
}

.PageLink {
  border: 1px solid transparent;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.88);
  border-radius: 0.3rem;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
}
.PageLink.active {
  border-color: #1677ff;
  color: #1677ff;
  cursor: default;
}
.PageLink.active:hover {
  background-color: #fff;
  opacity: 0.9;
}
.PageLink:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.TableHeader {
  display: flex;
  overflow-x: auto;
  background-color: var(--qt-cool-gray1);
}

.TableHeader::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.TableTr {
  display: block;
  min-width: 100%;
  cursor: pointer;
  border-bottom: 1px solid var(--qt-divider);
  padding-inline: 0.4rem;
}
.TableTr th {
  border: 0;
}
.TableTr td {
  border: 0;
}

.HeaderButton,
.HeaderButton > button {
  display: flex;
  align-items: center;
  gap: 5px;
}