*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

ol,
ul {
  list-style: none;
}

#root,
#__next {
  isolation: isolate;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

:export {
  lightGray1: #ffffff;
  lightGray2: #fafafa;
  lightGray3: #f5f5f5;
  lightGray4: #f0f0f0;
  lightGray5: #d9d9d9;
  lightGray6: #bfbfbf;
  lightGray7: #8c8c8c;
  lightGray8: #595959;
  lightGray9: #3f3f3f;
  lightGray10: #262626;
  lightGray11: #1f1f1f;
  lightGray12: #141414;
  lightGray13: #000000;
  lightCoolGray1: #f2f4f7;
  lightCoolGray2: #dde1e6;
  lightCoolGray3: #c1c7cd;
  lightCoolGray4: #a2a9b0;
  lightCoolGray5: #858c94;
  lightCoolGray6: #697077;
  lightCoolGray7: #4d5358;
  lightCoolGray8: #343a3f;
  lightCoolGray9: #21272a;
  lightCoolGray10: #121619;
  lightRed1: #fef9f9;
  lightRed2: #fef2f2;
  lightRed3: #fee2e2;
  lightRed4: #fecaca;
  lightRed5: #fca5a5;
  lightRed6: #f87171;
  lightRed7: #ef4c4c;
  lightRed8: #dc2626;
  lightRed9: #b91c1c;
  lightRed10: #991b1b;
  lightRed11: #7f1d1d;
  lightRed12: #450a0a;
  lightYellow1: #fefdf4;
  lightYellow2: #fefbe0;
  lightYellow3: #fef9be;
  lightYellow4: #feef81;
  lightYellow5: #ffe040;
  lightYellow6: #fdcc0d;
  lightYellow7: #f7bb00;
  lightYellow8: #de9802;
  lightYellow9: #a36307;
  lightYellow10: #854d0e;
  lightYellow11: #734012;
  lightYellow12: #422006;
  lightGreen1: #f7fff8;
  lightGreen2: #f0fdf1;
  lightGreen3: #dcfce0;
  lightGreen4: #bbf7c2;
  lightGreen5: #86ef94;
  lightGreen6: #4ade5e;
  lightGreen7: #23c939;
  lightGreen8: #16a329;
  lightGreen9: #158023;
  lightGreen10: #166522;
  lightGreen11: #14531e;
  lightGreen12: #052e16;
  lightBlue1: #f7fbff;
  lightBlue2: #eff6ff;
  lightBlue3: #dbeafe;
  lightBlue4: #bfdbfe;
  lightBlue5: #93c5fd;
  lightBlue6: #60a5fa;
  lightBlue7: #3b83f7;
  lightBlue8: #2563eb;
  lightBlue9: #1d4ed8;
  lightBlue10: #1e40af;
  lightBlue11: #1e3a8a;
  lightBlue12: #172554;
  lightWarmGray1: #f7f3f2;
  lightWarmGray2: #e5e0df;
  lightWarmGray3: #cac5c4;
  lightWarmGray4: #ada8a8;
  lightWarmGray5: #8f8b8b;
  lightWarmGray6: #726e6e;
  lightWarmGray7: #565151;
  lightWarmGray8: #3c3838;
  lightWarmGray9: #272525;
  lightWarmGray10: #171414;
  lightPrimary1: #eff6ff;
  lightPrimary2: #d6e9ff;
  lightPrimary3: #b2d7ff;
  lightPrimary4: #80bcff;
  lightPrimary5: #4da1ff;
  lightPrimary6: #0078fe;
  lightPrimary7: #0068db;
  lightPrimary8: #0059bd;
  lightPrimary9: #004899;
  lightPrimary10: #002957;
  lightPrimary11: #001d3d;
  lightChPrimaryInverse: #ffffff;
  lightChPrimary: rgba(0, 0, 0, 0.8470588235);
  lightChSecondary: rgba(0, 0, 0, 0.4470588235);
  lightChTertiary: rgba(0, 0, 0, 0.0274509804);
  lightChDisabledPlaceholder: rgba(0, 0, 0, 0.2470588235);
  lightChSuccess: #23c939;
  lightChActive: #3b83f7;
  lightChWarning: #f7bb00;
  lightChDanger: #f04c4c;
  lightBg: #ffffff;
  lightBgHover: rgba(0, 0, 0, 0.0666666667);
  lightBgFocus: rgba(0, 0, 0, 0.1490196078);
  lightDividerInverse: rgba(255, 255, 255, 0.4980392157);
  lightDivider: rgba(0, 0, 0, 0.1098039216);
  darkGray1: #000000;
  darkGray2: #141414;
  darkGray3: #1f1f1f;
  darkGray4: #262626;
  darkGray5: #3f3f3f;
  darkGray6: #595959;
  darkGray7: #8c8c8c;
  darkGray8: #bfbfbf;
  darkGray9: #d9d9d9;
  darkGray10: #f0f0f0;
  darkGray11: #f5f5f5;
  darkGray12: #fafafa;
  darkGray13: #ffffff;
  darkCoolGray1: #121619;
  darkCoolGray2: #21272a;
  darkCoolGray3: #343a3f;
  darkCoolGray4: #4d5358;
  darkCoolGray5: #697077;
  darkCoolGray6: #858c94;
  darkCoolGray7: #a2a9b0;
  darkCoolGray8: #c1c7cd;
  darkCoolGray9: #dde1e6;
  darkCoolGray10: #f3f4f7;
  darkRed1: #450a0a;
  darkRed2: #7f1d1d;
  darkRed3: #991b1b;
  darkRed4: #b91c1c;
  darkRed5: #dc2626;
  darkRed6: #f04c4c;
  darkRed7: #f87171;
  darkRed8: #fca5a5;
  darkRed9: #fecaca;
  darkRed10: #fee2e2;
  darkRed11: #fef2f2;
  darkRed12: #fef9f9;
  darkYellow1: #422006;
  darkYellow2: #734012;
  darkYellow3: #854d0e;
  darkYellow4: #a36307;
  darkYellow5: #de9802;
  darkYellow6: #f7bb00;
  darkYellow7: #fdcc0d;
  darkYellow8: #ffe040;
  darkYellow9: #feef81;
  darkYellow10: #fef9be;
  darkYellow11: #fefbe0;
  darkYellow12: #fefdf4;
  darkGreen1: #052e16;
  darkGreen2: #14531e;
  darkGreen3: #166522;
  darkGreen4: #158023;
  darkGreen5: #16a329;
  darkGreen6: #23c939;
  darkGreen7: #4ade5e;
  darkGreen8: #86ef94;
  darkGreen9: #bbf7c2;
  darkGreen10: #dcfce0;
  darkGreen11: #f0fdf1;
  darkGreen12: #f7fff8;
  darkBlue1: #172554;
  darkBlue2: #1e3a8a;
  darkBlue3: #1e40af;
  darkBlue4: #1d4ed8;
  darkBlue5: #2563eb;
  darkBlue6: #3b83f7;
  darkBlue7: #60a5fa;
  darkBlue8: #93c5fd;
  darkBlue9: #bfdbfe;
  darkBlue10: #dbeafe;
  darkBlue11: #eff6ff;
  darkBlue12: #f7fbff;
  darkWarmGray1: #171414;
  darkWarmGray2: #272525;
  darkWarmGray3: #3c3838;
  darkWarmGray4: #565151;
  darkWarmGray5: #726e6e;
  darkWarmGray6: #8f8b8b;
  darkWarmGray7: #ada8a8;
  darkWarmGray8: #cac5c4;
  darkWarmGray9: #e5e0df;
  darkWarmGray10: #f7f3f2;
  darkPrimary1: #001d3d;
  darkPrimary2: #002957;
  darkPrimary3: #004899;
  darkPrimary4: #0059bd;
  darkPrimary5: #0068db;
  darkPrimary6: #0078fe;
  darkPrimary7: #4da1ff;
  darkPrimary8: #80bcff;
  darkPrimary9: #b2d7ff;
  darkPrimary10: #d6e9ff;
  darkPrimary11: #eff6ff;
  darkChPrimaryInverse: #2d2d2d;
  darkChPrimary: rgba(255, 255, 255, 0.8470588235);
  darkChSecondary: rgba(255, 255, 255, 0.4470588235);
  darkChTertiary: rgba(255, 255, 255, 0.0666666667);
  darkChDisabledPlaceholder: rgba(255, 255, 255, 0.2980392157);
  darkChSuccess: #4ade5e;
  darkChActive: #60a5fa;
  darkChWarning: #fdcc0d;
  darkChDanger: #f87171;
  darkBg: rgba(255, 255, 255, 0.0666666667);
  darkBgHover: rgba(255, 255, 255, 0.1294117647);
  darkBgFocus: rgba(255, 255, 255, 0.2);
  darkDividerInverse: rgba(0, 0, 0, 0.4980392157);
  darkDivider: rgba(255, 255, 255, 0.2);
}

body,
body[data-theme=light] {
  --qt-gray1: #ffffff;
  --qt-gray2: #fafafa;
  --qt-gray3: #f5f5f5;
  --qt-gray4: #f0f0f0;
  --qt-gray5: #d9d9d9;
  --qt-gray6: #bfbfbf;
  --qt-gray7: #8c8c8c;
  --qt-gray8: #595959;
  --qt-gray9: #3f3f3f;
  --qt-gray10: #262626;
  --qt-gray11: #1f1f1f;
  --qt-gray12: #141414;
  --qt-gray13: #000000;
  --qt-cool-gray1: #f2f4f7;
  --qt-cool-gray2: #dde1e6;
  --qt-cool-gray3: #c1c7cd;
  --qt-cool-gray4: #a2a9b0;
  --qt-cool-gray5: #858c94;
  --qt-cool-gray6: #697077;
  --qt-cool-gray7: #4d5358;
  --qt-cool-gray8: #343a3f;
  --qt-cool-gray9: #21272a;
  --qt-cool-gray10: #121619;
  --qt-red1: #fef9f9;
  --qt-red2: #fef2f2;
  --qt-red3: #fee2e2;
  --qt-red4: #fecaca;
  --qt-red5: #fca5a5;
  --qt-red6: #f87171;
  --qt-red7: #ef4c4c;
  --qt-red8: #dc2626;
  --qt-red9: #b91c1c;
  --qt-red10: #991b1b;
  --qt-red11: #7f1d1d;
  --qt-red12: #450a0a;
  --qt-yellow1: #fefdf4;
  --qt-yellow2: #fefbe0;
  --qt-yellow3: #fef9be;
  --qt-yellow4: #feef81;
  --qt-yellow5: #ffe040;
  --qt-yellow6: #fdcc0d;
  --qt-yellow7: #f7bb00;
  --qt-yellow8: #de9802;
  --qt-yellow9: #a36307;
  --qt-yellow10: #854d0e;
  --qt-yellow11: #734012;
  --qt-yellow12: #422006;
  --qt-green1: #f7fff8;
  --qt-green2: #f0fdf1;
  --qt-green3: #dcfce0;
  --qt-green4: #bbf7c2;
  --qt-green5: #86ef94;
  --qt-green6: #4ade5e;
  --qt-green7: #23c939;
  --qt-green8: #16a329;
  --qt-green9: #158023;
  --qt-green10: #166522;
  --qt-green11: #14531e;
  --qt-green12: #052e16;
  --qt-blue1: #f7fbff;
  --qt-blue2: #eff6ff;
  --qt-blue3: #dbeafe;
  --qt-blue4: #bfdbfe;
  --qt-blue5: #93c5fd;
  --qt-blue6: #60a5fa;
  --qt-blue7: #3b83f7;
  --qt-blue8: #2563eb;
  --qt-blue9: #1d4ed8;
  --qt-blue10: #1e40af;
  --qt-blue11: #1e3a8a;
  --qt-blue12: #172554;
  --qt-warm-gray1: #f7f3f2;
  --qt-warm-gray2: #e5e0df;
  --qt-warm-gray3: #cac5c4;
  --qt-warm-gray4: #ada8a8;
  --qt-warm-gray5: #8f8b8b;
  --qt-warm-gray6: #726e6e;
  --qt-warm-gray7: #565151;
  --qt-warm-gray8: #3c3838;
  --qt-warm-gray9: #272525;
  --qt-warm-gray10: #171414;
  --qt-primary1: #eff6ff;
  --qt-primary2: #d6e9ff;
  --qt-primary3: #b2d7ff;
  --qt-primary4: #80bcff;
  --qt-primary5: #4da1ff;
  --qt-primary6: #0078fe;
  --qt-primary7: #0068db;
  --qt-primary8: #0059bd;
  --qt-primary9: #004899;
  --qt-primary10: #002957;
  --qt-primary11: #001d3d;
  --qt-ch-primary-inverse: #ffffff;
  --qt-ch-primary: rgba(0, 0, 0, 0.8470588235);
  --qt-ch-secondary: rgba(0, 0, 0, 0.4470588235);
  --qt-ch-tertiary: rgba(0, 0, 0, 0.0274509804);
  --qt-ch-disabled-placeholder: rgba(0, 0, 0, 0.2470588235);
  --qt-ch-success: #23c939;
  --qt-ch-active: #3b83f7;
  --qt-ch-warning: #f7bb00;
  --qt-ch-danger: #f04c4c;
  --qt-bg: #ffffff;
  --qt-bg-hover: rgba(0, 0, 0, 0.0666666667);
  --qt-bg-focus: rgba(0, 0, 0, 0.1490196078);
  --qt-divider-inverse: rgba(255, 255, 255, 0.4980392157);
  --qt-divider: rgba(0, 0, 0, 0.1098039216);
}

body[data-theme=dark] {
  --qt-gray1: #000000;
  --qt-gray2: #141414;
  --qt-gray3: #1f1f1f;
  --qt-gray4: #262626;
  --qt-gray5: #3f3f3f;
  --qt-gray6: #595959;
  --qt-gray7: #8c8c8c;
  --qt-gray8: #bfbfbf;
  --qt-gray9: #d9d9d9;
  --qt-gray10: #f0f0f0;
  --qt-gray11: #f5f5f5;
  --qt-gray12: #fafafa;
  --qt-gray13: #ffffff;
  --qt-cool-gray1: #121619;
  --qt-cool-gray2: #21272a;
  --qt-cool-gray3: #343a3f;
  --qt-cool-gray4: #4d5358;
  --qt-cool-gray5: #697077;
  --qt-cool-gray6: #858c94;
  --qt-cool-gray7: #a2a9b0;
  --qt-cool-gray8: #c1c7cd;
  --qt-cool-gray9: #dde1e6;
  --qt-cool-gray10: #f3f4f7;
  --qt-red1: #450a0a;
  --qt-red2: #7f1d1d;
  --qt-red3: #991b1b;
  --qt-red4: #b91c1c;
  --qt-red5: #dc2626;
  --qt-red6: #f04c4c;
  --qt-red7: #f87171;
  --qt-red8: #fca5a5;
  --qt-red9: #fecaca;
  --qt-red10: #fee2e2;
  --qt-red11: #fef2f2;
  --qt-red12: #fef9f9;
  --qt-yellow1: #422006;
  --qt-yellow2: #734012;
  --qt-yellow3: #854d0e;
  --qt-yellow4: #a36307;
  --qt-yellow5: #de9802;
  --qt-yellow6: #f7bb00;
  --qt-yellow7: #fdcc0d;
  --qt-yellow8: #ffe040;
  --qt-yellow9: #feef81;
  --qt-yellow10: #fef9be;
  --qt-yellow11: #fefbe0;
  --qt-yellow12: #fefdf4;
  --qt-green1: #052e16;
  --qt-green2: #14531e;
  --qt-green3: #166522;
  --qt-green4: #158023;
  --qt-green5: #16a329;
  --qt-green6: #23c939;
  --qt-green7: #4ade5e;
  --qt-green8: #86ef94;
  --qt-green9: #bbf7c2;
  --qt-green10: #dcfce0;
  --qt-green11: #f0fdf1;
  --qt-green12: #f7fff8;
  --qt-blue1: #172554;
  --qt-blue2: #1e3a8a;
  --qt-blue3: #1e40af;
  --qt-blue4: #1d4ed8;
  --qt-blue5: #2563eb;
  --qt-blue6: #3b83f7;
  --qt-blue7: #60a5fa;
  --qt-blue8: #93c5fd;
  --qt-blue9: #bfdbfe;
  --qt-blue10: #dbeafe;
  --qt-blue11: #eff6ff;
  --qt-blue12: #f7fbff;
  --qt-warm-gray1: #171414;
  --qt-warm-gray2: #272525;
  --qt-warm-gray3: #3c3838;
  --qt-warm-gray4: #565151;
  --qt-warm-gray5: #726e6e;
  --qt-warm-gray6: #8f8b8b;
  --qt-warm-gray7: #ada8a8;
  --qt-warm-gray8: #cac5c4;
  --qt-warm-gray9: #e5e0df;
  --qt-warm-gray10: #f7f3f2;
  --qt-primary1: #001d3d;
  --qt-primary2: #002957;
  --qt-primary3: #004899;
  --qt-primary4: #0059bd;
  --qt-primary5: #0068db;
  --qt-primary6: #0078fe;
  --qt-primary7: #4da1ff;
  --qt-primary8: #80bcff;
  --qt-primary9: #b2d7ff;
  --qt-primary10: #d6e9ff;
  --qt-primary11: #eff6ff;
  --qt-ch-primary-inverse: #2d2d2d;
  --qt-ch-primary: rgba(255, 255, 255, 0.8470588235);
  --qt-ch-secondary: rgba(255, 255, 255, 0.4470588235);
  --qt-ch-tertiary: rgba(255, 255, 255, 0.0666666667);
  --qt-ch-disabled-placeholder: rgba(255, 255, 255, 0.2980392157);
  --qt-ch-success: #4ade5e;
  --qt-ch-active: #60a5fa;
  --qt-ch-warning: #fdcc0d;
  --qt-ch-danger: #f87171;
  --qt-bg: rgba(255, 255, 255, 0.0666666667);
  --qt-bg-hover: rgba(255, 255, 255, 0.1294117647);
  --qt-bg-focus: rgba(255, 255, 255, 0.2);
  --qt-divider-inverse: rgba(0, 0, 0, 0.4980392157);
  --qt-divider: rgba(255, 255, 255, 0.2);
}