.Loading {
  background-color: #f7f7f7;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -0.8px;
  color: #222;

  .List {
    display: flex;
    flex-direction: column;
    margin: -4px;
    padding: 0 40px;

    .ListItem {
      flex: 1 1 0;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 4px 0;
      padding: 8px;
      border-radius: 100px;
      background-color: #fff;

      .Oval {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin: 0 16px 0 0;
        padding: 4px;
        background-color: var(--emart-point-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .Footer {
    flex: 1 1 0;
    padding-bottom: 40px;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: -0.46px;
    color: #767676;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}