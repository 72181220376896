.Link {
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}
.Link[data-delete=true] {
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 5px 8px;
}
.Link a {
  display: inline-flex;
  align-items: center;
}
.Link a span {
  margin-left: 3px;
  display: inline-block;
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Link .DeleteIcon {
  margin-left: 12px;
  cursor: pointer;
}