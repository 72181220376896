@charset "UTF-8";
.search_inputdown {
  height: 34px;
  margin-right: 10px;
  display: inline-flex;
  padding: 5px 15px;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c3c8cc;
  background: #fff;
}
.search_inputdown div:first-child {
  display: inline-flex;
}
.search_inputdown .search_inputdown_select {
  display: inline-flex;
  padding: 7px 15px;
  align-items: center;
  border: none;
  background: #fff;
  padding: 0;
}
.search_inputdown .search_inputdown_select::after {
  display: none;
}
.search_inputdown .search_inputdown_select:hover {
  border-color: #000;
  color: #000;
  background-color: #fff;
}
.search_inputdown .search_inputdown_select:active {
  outline: none !important;
  box-shadow: none !important;
}
.search_inputdown .search_inputdown_select div {
  margin-right: 10px;
  color: #9199a1;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
}
.search_inputdown .search_inputdown_select .search_dropdown_icon {
  display: flex;
  transition: transform 0.3s ease;
  margin: 0;
}
.search_inputdown .search_inputdown_select .search_dropdown_icon.open {
  transform: rotate(-180deg); /* 열린 상태에서의 회전 설정 */
}
.search_inputdown input {
  color: #000;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin: 0px;
  white-space: inherit;
  flex: auto;
  width: auto;
  min-width: auto;
  padding: 0px;
  border-radius: 0px;
  overflow: auto;
}
.search_inputdown input:focus {
  outline: none;
  box-shadow: none;
  caret-color: #0078fe;
}
.search_inputdown:hover {
  border: 1px solid #4ca0fe;
  background: #fff;
}
.search_inputdown:active {
  border: 1px solid #0078fe;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}
.search_inputdown:focus, .search_inputdown:focus-within, .search_inputdown:focus-visible {
  background: #fff !important;
  border: 1px solid #4ca0fe !important;
  box-shadow: 0px 0px 0px 1px #7dc8ff !important;
  outline: none !important;
}

.search_dropdown_menu {
  transform: translate3d(-15px, 30px, 0px) !important;
}

.search_dropdown_item {
  color: #000 !important;
  /* Caption 1 */
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
}
.search_dropdown_item:hover {
  background: #f7f7f8 !important;
}
.search_dropdown_item:active {
  background: #e9eef3 !important;
}