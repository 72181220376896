.Container {
  display: flex;
  padding: 0;
  justify-content: flex-end;
}
.Container.Focus {
  animation: shake 0.25s;
  animation-iteration-count: 1;
}
.Container .Resending {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.Container .Resending .ResendButton {
  display: flex;
  padding: 0.1rem;
  margin: 0;
  border: 0;
  color: var(--bs-secondary);
  background: var(--bs-gray-200, #eef5f9);
  margin-right: 0.5rem;
}
.Container:hover .MessageOption .Button {
  opacity: 1;
  pointer-events: auto;
}
.Container .MessageOption {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 3rem;
  gap: 4px;
}
.Container .MessageOption .Button {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: var(--qt-cool-gray7);
  border-radius: 18px;
  font-size: 0.7em;
  border: 0;
  height: 32px;
  min-width: 32px;
  padding-inline: 8px;
}
.Container .MessageOption .Button:hover {
  background-color: var(--qt-cool-gray8);
}
.Container .MessageOption .Button .Icon {
  width: 16px;
  aspect-ratio: 1;
  color: var(--qt-ch-primary-inverse);
}
.Container .MessageOption .Button .Icon:hover {
  color: var(--qt-gray4);
}
.Container .Content {
  padding-right: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.Container .Content .ContentInner {
  display: flex;
  align-items: flex-end;
  gap: 6px;
}
.Container .Content .NotConfirmCount {
  display: flex;
  align-items: flex-end;
  font-size: 12.5px;
  color: var(--qt-primary6);
}
.Container .Content .TtsCheck {
  display: flex;
  align-items: flex-end;
  color: var(--qt-cool-gray7);
  font-size: 11px;
}
.Container .Content .Date {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 11px;
  color: var(--qt-gray7);
}

@keyframes shake {
  0% {
    transform: translate(0px, 0px);
  }
  33% {
    transform: translate(0px, -5px);
  }
  66% {
    transform: translate(0px, 5px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}