.Button {
  cursor: pointer;
  color: var(--qt-cool-gray6);
  transition: color 0.2s;
}
.Button:hover {
  color: var(--qt-primary6);
}
.Button.Disabled {
  cursor: not-allowed;
  color: var(--qt-ch-disabled-placeholder) !important;
}