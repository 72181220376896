.search_input_box {
  display: inline-flex;
  padding: 5px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #c3c8cc;
  background: #fff;
  width: auto;
  margin-right: 10px;
  height: 34px;
  min-width: 100px;
}
.search_input_box .search_input_label {
  color: #9199a1;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  margin: 0px;
  background-color: white;
  border: none;
  padding: 0px;
  display: inline-flex;
}
.search_input_box .search_input {
  color: #000;
  padding-right: 0 !important;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin: 0px;
  white-space: inherit;
  flex: auto;
  min-width: auto;
  padding: 0px;
  display: inline-flex;
}
.search_input_box .search_input:focus {
  outline: none;
  box-shadow: none;
  caret-color: #0078fe;
}
.search_input_box:hover {
  border: 1px solid #4ca0fe;
  background: #fff;
}
.search_input_box:active {
  border: 1px solid #0078fe;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}
.search_input_box:focus, .search_input_box:focus-within, .search_input_box:focus-visible {
  background: #fff !important;
  border: 1px solid #4ca0fe !important;
  box-shadow: 0px 0px 0px 1px #7dc8ff !important;
  outline: none !important;
}