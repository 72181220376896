.CloseButton{
  color: var(--bs-secondary);
  cursor: pointer;
}
.HeaderContainer{
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--gray-200, #EEF5F9);
  background: #FFF;
  .SubHeader{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: height .2s ease-in-out;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    .WorkTime{
      display: flex;
      align-items: center;
      font-size: .75rem;
      font-weight: 700;
      color: var(--bs-gray-900);
      .SubTitle{
        padding-left: 4px;
        padding-right: 7px;
      }
    }
    .CloseDay{
      display: flex;
      align-items: center;
      font-size: .75rem;
      font-weight: 700;
      color: var(--bs-gray-900);
      .SubTitle{
        padding-left: 4px;
        padding-right: 18px;
      }
    }
  }
  .Header{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;

    .Left{
      flex: 1;
    }
    .Center{
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      overflow: hidden;
      margin-left: 8px;
      width: 0;
      .Badge{
        white-space: nowrap;
        display: flex;
        font-weight: 700;
        padding: 2px 4px;
        font-size: .75rem;
        border-radius: 1rem;
        color: #fff;
        margin-right: 4px;
        background-color: #aaa;
        &.Active{
          color: #222 !important;
          background-color: #ffe926 !important;
        }
      }
      .Title{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1rem;
        color: #222222;

        margin-right: 16px;
      }
    }
    .Right{
      display: flex;
      flex: 1;
      justify-content: flex-end;
      .RequestButton{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left:1rem;
      }
    }

    .SearchHeader{
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: .5rem 1rem;
      transition: all ease-in .25s;
      transform: translateY(-100%);
      border-bottom: 2px solid var(--gray-200, #EEF5F9);
      background: #FFF;
      &.IsOpen{
        transform: translateY(0);
      }
      .Search{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .Input{
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 0 .5rem;
        input{
          outline-color: transparent;
          border: 0 !important;
          color: var(--bs-secondary);
          &:focus-visible {
            outline: none;
          }
        }
      }
      .Cancel{
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}
