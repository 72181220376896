.Reply {
  display: flex;
  flex-flow: column;
  padding: 8px 10px;
  font-size: 12px;
}
.Reply .Content {
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Reply.Out {
  color: var(--qt-cool-gray3);
}
.Reply.Out .Name {
  color: var(--qt-primary2);
}
.Reply.In {
  color: var(--qt-cool-gray6);
}
.Reply.In .Name {
  color: var(--qt-primary5);
}

.Line {
  margin: 0 1rem;
}