.CharacterContainer {
  display: flex;
  align-items: flex-end;
  z-index: 0;
}
.CharacterContainer .Message {
  display: flex;
  justify-content: center;
  align-items: center;
}
.CharacterContainer .Message .Content {
  position: absolute;
  transform: translate(-0.4rem, -0.6rem);
  font-weight: normal;
  line-height: 1.38;
  z-index: 2;
}
.CharacterContainer .Character {
  z-index: 2;
  transform: translate(-2.2rem, -0.6rem);
}

.Faq {
  display: flex;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: -0.46px;
  align-items: center;
  color: #424242;
  margin-bottom: 0.5rem;
}