@charset "UTF-8";
.search_dropdown {
  margin-right: 10px;
}

.search_dropdown_select {
  display: inline-flex;
  height: 34px;
  padding: 7px 15px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #c3c8cc;
  background: #fff;
}
.search_dropdown_select::after {
  display: none;
}
.search_dropdown_select:hover {
  border: 1px solid #4ca0fe;
  background: #fff;
}
.search_dropdown_select:active {
  border: 1px solid #0078fe;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
}
.search_dropdown_select:focus, .search_dropdown_select:focus-within, .search_dropdown_select:focus-visible {
  background: #fff !important;
  border: 1px solid #4ca0fe !important;
  box-shadow: 0px 0px 0px 1px #7dc8ff !important;
  outline: none !important;
}
.search_dropdown_select div {
  color: #000;
  /* Subtitle 3 */
  font-family: Noto Sans KR;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
}
.search_dropdown_select div label {
  color: #9199a1;
  margin-right: 10px;
}
.search_dropdown_select .search_dropdown_icon {
  display: flex;
  transition: transform 0.3s ease;
  margin: 0;
}
.search_dropdown_select .search_dropdown_icon.open {
  transform: rotate(-180deg); /* 열린 상태에서의 회전 설정 */
}

.search_dropdown_menu {
  font-size: 13px;
}

.search_dropdown_item {
  color: #000 !important;
  /* Caption 1 */
  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
}
.search_dropdown_item:hover {
  background: #f7f7f8 !important;
}
.search_dropdown_item:active {
  background: #e9eef3 !important;
}