.BackDrop {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: flex;
}
.BackDrop .Dialog {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1rem;
  gap: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  min-width: 20rem;
}
.BackDrop .Dialog .Title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.0125rem;
}
.BackDrop .Dialog .Content {
  color: var(--bs-secondary);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: -0.0125rem;
  white-space: pre-wrap;
}
.BackDrop .Dialog .ButtonGroup {
  display: flex;
  justify-content: flex-end;
}