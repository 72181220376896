.Title {
  color: #000;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.0125rem;
  padding-bottom: 1rem;
}

.Agent {
  border-radius: 0.5rem;
  border: 1px solid var(--gray-500, #ADB5BD);
  background: #FFF;
  margin-bottom: 1rem;
}

.Reason {
  margin-bottom: 1rem;
}

.Footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Footer button {
  margin-left: 0.5rem;
}