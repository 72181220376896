.Channel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 1px;
  height: 100%;
  flex: 1 1 0;
}
.Channel .Scroll {
  position: relative;
  display: block;
  flex: 1 1 0;
  overflow-y: auto;
  min-height: 0;
}
.Channel .ChannelPager {
  display: flex;
  justify-content: center;
  padding: 0.375rem;
}
.Channel .ChannelPager div {
  display: flex;
  justify-content: center;
}
.Channel .ChannelPager div button {
  border: 0;
  color: #009688;
  font-size: 0.875rem;
  letter-spacing: -0.4px;
  background: none;
  margin: 0;
  padding: 0 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Channel .ChannelPager div button.On {
  background-color: #009688;
  color: #f7f7f7;
}
.Channel .ChannelPager div:nth-child(1) {
  flex: 1 0 0;
}
.Channel .ChannelPager div:nth-child(2) {
  flex: 1 0 0;
}
.Channel .ChannelPager div:nth-child(2) button {
  padding: 0.5rem;
}
.Channel .ChannelPager div:nth-child(3) {
  flex: 1 0 0;
}

.Pagination {
  display: flex;
  justify-content: center;
  padding: 6px;
  gap: 4px;
}
.Pagination :global .pt-pagelink {
  width: 24px;
  height: 24px;
  font-size: 11px;
  margin: 0;
}