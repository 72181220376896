.Container {
  width: 100%;
  height: 100%;
}
.Container .Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.5rem;
  background-color: #FFFFFF;
}
.Container .Header .Label {
  font-weight: bold;
  font-size: 1.125rem;
  margin-bottom: 0;
}
.Container .Header .NotUseButton {
  display: flex;
  flex-direction: column;
  width: 1.625rem;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.Container .Header .UseButton {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}
.Container .Content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--bs-gray-100);
  padding: 1rem;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}
.Container .Content .Character {
  width: 50%;
  max-width: 20rem;
  min-width: 10rem;
  flex: 1;
  display: flex;
  align-items: flex-end;
}
.Container .Content .LabelWrap {
  width: 100%;
}
.Container .Content .LabelWrap .Label {
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6C757D;
}
.Container .Content .LabelWrap .Title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  white-space: pre-wrap;
  padding-bottom: 1rem;
}