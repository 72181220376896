.Container {
  width: 100%;
  height: 100%;

  .Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background-color: #FFFFFF;

    .Label {
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 0;
      color: var(--bs-gray-900)
    }

    .NotUseButton {
      display: flex;
      flex-direction: column;
      width: 1.625rem;
      align-items: center;
      justify-content: center;

    }

    .UseButton {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }


  .Content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--bs-gray-100);
    padding: 1rem;
    align-items: center;
    height: 100%;

    .Character {
      width: 50%;
      max-width: 20rem;
      min-width: 10rem;
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .LabelWrap {
      width: 100%;

      .Label {
        margin-bottom: 0;
        font-size: 0.875rem;
        color: #6C757D;
      }

      .Title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}