.MentionWrap {
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  padding-right: 2rem;
}

.Mention {
  position: relative;
  z-index: 1 !important;
  color: var(--bs-primary);
  background-color: transparent !important;
  pointer-events: none;
  text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white;
}

.Toggle {
  display: flex;
  position: absolute;
  right: -2rem;
  top: 0;
  z-index: 99;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  fill: var(--bs-secondary);
}
.Toggle .Button {
  background: none;
  padding: 0.25rem 0.5rem;
  border: 0;
  border-radius: 0.5rem;
}
.Toggle :hover {
  fill: var(--bs-dark);
}