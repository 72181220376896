.Recommend {
  padding: 12px 14px;
  max-height: 340px;
  min-height: 135px;
  overflow-y: auto;
  scroll-behavior: smooth;
  border-top: 4px solid var(--qt-divider);
  background-color: var(--qt-gray1);
}
.Recommend .Content {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Recommend .Content .Title {
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
.Recommend .Content .Title > .ReplyWrap {
  display: flex;
  flex-basis: 0;
  flex: 1;
}
.Recommend .Content .Title > .ReplyWrap .Reply {
  font-size: 13px;
  color: var(--qt-ch-secondary);
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.Recommend .Content li {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 8px;
}
.Recommend .Content li:not(:last-of-type) {
  border-bottom: 1px solid var(--qt-divider);
}
.Recommend .Content li form {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.Recommend .Content li form .Icon {
  color: var(--qt-ch-secondary);
}
.Recommend .Content li form .Textarea {
  height: 52px;
  flex: 1;
  font-size: 13px;
  border-radius: 4px;
}
.Recommend .Content li form button {
  padding-inline: 12px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  color: var(--qt-ch-primary);
  border: 1px solid var(--qt-gray5);
  border-radius: 4px;
}