/* .react-bootstrap-table {
  min-height:700px;
} */

.react-bootstrap-table > table {
  table-layout: fixed;
  margin-bottom: 0;
}

.react-bootstrap-table * .selection-cell-header {
  width: 21px;
}

.react-bootstrap-table > table > tbody {
  color: var(--black, #000);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0px;
}

.react-bootstrap-table > table > tbody > tr {
  height: 40px;
  max-height: 40px;
  text-align: center;
  overflow-x: scroll;
  cursor: pointer;
}

.react-bootstrap-table > table > tbody > tr > td {
  /* max-width:200px; */
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.react-bootstrap-table > table > thead {
  border-bottom: 1px solid var(--grey-3, #e8e8e8);
  background: var(--cool-grey-1, #f7f7f8);
  color: var(--cool-gray-5, #5e666e);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Noto Sans KR;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.react-bootstrap-table > table > thead > tr {
  text-align: center;
  cursor: pointer;
}

.react-bootstrap-table > table > thead > tr > th {
  min-width: 60px;
}

.react-bootstrap-table-page-btns-ul .page-item.active .page-link {
  color: var(--blue-3, #4ca0fe);
  background-color: #fff;
  border-color: #fff;
}
.react-bootstrap-table-page-btns-ul .page-item.active .page-link:focus:hover {
  box-shadow: none;
}

.qtable_col_pre {
  white-space: normal !important;
  text-overflow: unset !important;
  overflow: auto !important;
}

.qtable_select_row {
  background-color: rgba(244, 248, 252, 1);
  width: 20px;
  /* background-color: red; */
}
