.Title {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: -0.2px;
}

.Button {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}