.NoChannel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  font-size: 15px;
  color: var(--qt-cool-gray6);
}
.NoChannel .Icon {
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}