.ScrollToBottom {
  bottom: 0;
  width: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-inline: 1rem;
  padding-bottom: 2.2rem;
}
.ScrollToBottom.isUser {
  padding-bottom: 1rem;
}
.ScrollToBottom .Button {
  opacity: 0;
  pointer-events: none;
  justify-content: space-between;
  border-color: transparent;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  transition: opacity 0.3s;
  border-radius: 17px;
  background: var(--qt-cool-gray10);
  border: 1px solid var(--qt-divider);
}
.ScrollToBottom .Button.isNotBottom {
  opacity: 0.8;
  pointer-events: auto;
}
.ScrollToBottom .Button.isNew {
  width: 100%;
  border-radius: 4px;
}
.ScrollToBottom .Button .Icon {
  min-width: 18px;
  min-height: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: var(--qt-gray1);
}
.ScrollToBottom .Button .LastMessage {
  display: flex;
  align-items: center;
  font-size: 12px;
  position: relative;
  min-width: 0;
}
.ScrollToBottom .Button .LastMessage .Sender {
  display: flex;
  align-items: center;
  color: var(--qt-cool-gray1);
  flex: 0 0 auto;
  margin-right: 0.5rem;
}
.ScrollToBottom .Button .LastMessage .Sender .Avatar {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 0.5rem;
  overflow: hidden;
}
.ScrollToBottom .Button .LastMessage .Content {
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  padding-right: 0.5rem;
  color: var(--qt-cool-gray3);
}