.Menu {
  z-index: 1;
  border: 1px solid #E8E8E8;
  border-radius: 0.625rem;
  background-color: #fff;
  margin-bottom: 0.625rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.Menu .Search {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-200, #EEF5F9);
}
.Menu .Search .Icon {
  background: transparent;
  border: 0;
}
.Menu .Search input {
  margin: 0.25rem;
  border: 0;
}

.Active:hover {
  background: var(--gray-200, #EEF5F9);
}

.InActive {
  background: var(--gray-200, rgba(238, 245, 249, 0.51));
}

.AgentProfile {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 1rem 0.75rem;
}
.AgentProfile .Avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.AgentProfile .Avatar img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}
.AgentProfile .Name {
  color: var(--secondary, #6C757D);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.0125rem;
}