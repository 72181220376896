.Container {
  width: 100%;
  height: 100%;
}
.Container .Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background-color: var(--bs-gray-100);
  padding: 1rem;
  height: 100%;
}
.Container .Content .Label {
  margin-bottom: 0;
  white-space: pre-wrap;
}
.Container .Content .RatingWrap {
  width: 100%;
  max-width: 20rem;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  align-items: center;
}
.Container .Content .RatingWrap .CommentWrap .TextArea {
  resize: none;
  border-radius: 0;
  width: 100%;
  resize: none;
  max-height: 136px;
  font-size: 0.875rem;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
  outline-color: transparent;
  border: 0 !important;
  color: var(--bs-secondary);
}
.Container .Content .RatingWrap .CommentWrap .TextArea::placeholder {
  font-size: 1rem;
  color: var(--secondary, #6C757D);
}
.Container .Content .RatingWrap .CommentWrap .TextArea:focus-visible {
  outline: none;
}
.Container .Content .RatingWrap .Rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Container .Content .RatingWrap .Rating .StarIcon {
  color: #CACACA;
  font-size: 2rem;
  cursor: pointer;
}
.Container .Content .RatingWrap .Rating .StarIcon.Active {
  color: #FFE926;
}
.Container .Content .RatingWrap .Rating .RatingLabel {
  font-size: 0.75rem;
  font-weight: bold;
}
.Container .Content .CommentWrap {
  width: 100%;
}
.Container .Content .CommentWrap .Label {
  font-size: 0.875rem;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
}
.Container .Content .CommentWrap .TextArea {
  border-radius: 0;
  resize: none;
}