.qdatagrid {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid var(--grey-5, #ccc);
  background: var(--grey-1, #fefefe);
  box-shadow: 0px 0px 9px 0px #e8e8e8;
}

.qdatagrid_row,
.pm_0 {
  margin: 0;
  padding: 0;
  flex: 1;
}
.qdatagrid_row > div,
.pm_0 > div {
  margin: 0;
  padding: 0;
}

.qdatagrid_row {
  display: flex;
  height: 100%;
}

.pm_0 {
  height: 100%;
  overflow: hidden;
}

.qdatagrid_filter {
  height: 50px;
  width: 100%;
  margin: 0;
  border-radius: 4px 4px 0 0;
  background: var(--cool-grey-1, #f7f7f8);
  border-bottom: 1px solid var(--grey-5, #ccc);
}

.qdatagrid_filter_dropdown {
  margin-left: 0 !important;
}

.qdatagrid_filter_btn {
  margin: 10px;
  display: inline-block;
  padding: 3px 10px 5px 10px;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  border-radius: 6px;
  border: 1px solid #0060cb;
  background: var(--quantum-blue, #0078fe);
  color: var(--white, #fff);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.qdatagrid_filter_btn ::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  cursor: pointer;
  box-sizing: border-box;
}

.qdatagrid_filter_btn_dropdown_item {
  font-size: 13px;
  margin-left: 12px;
}

.qdatagrid_filter_custom {
  text-align: right;
  margin-right: 20px;
}

.qdatagrid_filter_item_btn {
  display: inline-flex;
  margin-left: 10px;
  padding: 3px 5px 5px 10px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid var(--unnamed, #0060cb);
  background: var(--white, #fff);
  color: var(--unnamed, #0060cb);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Noto Sans KR;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.qdatagrid_filter_item_btn::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 15 15' fill='none'%3E%3Cg clip-path='url(%23clip0_1148_1379)'%3E%3Cpath d='M7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15C11.6421 15 15 11.6421 15 7.5C14.9955 3.35971 11.6403 0.00448242 7.5 0ZM9.99999 9.11687C10.254 9.35063 10.2704 9.74602 10.0366 9.99999C9.80285 10.254 9.40746 10.2704 9.15349 10.0366C9.14077 10.0249 9.12858 10.0127 9.11687 9.99999L7.5 8.38374L5.88375 9.99999C5.63546 10.2398 5.2398 10.2329 5.00001 9.98461C4.7661 9.74241 4.7661 9.35845 5.00001 9.11625L6.61626 7.5L5.00001 5.88375C4.76022 5.63546 4.7671 5.2398 5.01539 5.00001C5.25759 4.7661 5.64155 4.7661 5.88375 5.00001L7.5 6.61626L9.11687 5.00001C9.35063 4.74604 9.74602 4.72963 9.99999 4.96339C10.254 5.19715 10.2704 5.59254 10.0366 5.84651C10.0249 5.85923 10.0127 5.87142 9.99999 5.88313L8.38374 7.5L9.99999 9.11687Z' fill='%230060CB'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1148_1379'%3E%3Crect width='15' height='15' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.qdatagrid_filter_search {
  display: flex;
  float: right;
}
.qdatagrid_filter_search .qdatagrid_filter_search_input {
  display: flex;
  width: 250px;
  padding: 9px 155px 9px 15px;
  align-items: center;
  border-radius: 9px;
  border: 1px solid var(--cool-grey-3, #c3c8cc);
  background: var(--white, #fff);
  font-size: 12px;
  height: 30px;
  margin-right: 10px;
}
.qdatagrid_filter_search .qdatagrid_filter_search_btn {
  display: inline-flex;
  padding: 9px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid #0060cb;
  background: var(--quantum-blue, #0078fe);
  font-size: 12px;
  height: 30px;
}

.qdatagrid_outline {
  height: 100%;
  display: flex;
}
.qdatagrid_outline > div {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.qdatagrid_outline * > th:not(:last-child)::after {
  content: "";
  height: 50%;
  width: 2px;
  background-color: #e8e8e8;
  position: absolute;
  left: 100%;
}
.qdatagrid_outline * > td {
  border-top: 2px solid #e8e8e8;
}
.qdatagrid_outline * > td:not(:last-child) {
  border-right: 2px solid #e8e8e8;
}

.qdatagrid_headerline {
  height: 100%;
}
.qdatagrid_headerline > :nth-child(1) {
  width: 100% !important;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.bootstrap_table {
  width: 100% !important;
  height: 100%;
}
.bootstrap_table > :nth-child(1) {
  min-height: calc(100% - 50px) !important;
}

.qtable_page_size {
  color: var(--grey-6, #aaa);
  font-size: 14px;
  font-weight: 500;
}

.qtable_page_btn_group {
  display: block;
  padding: 9px 15px;
  height: 50px;
  border-top: 1px solid var(--grey-5, #ccc);
  background: var(--grey-1, #fefefe);
  box-shadow: 0px 0px 9px 0px #e8e8e8;
}
.qtable_page_btn_group span {
  color: var(--black, #000);
  font-size: 14px;
  font-weight: 500;
}
.qtable_page_btn_group span:nth-child(3) {
  color: var(--grey-6, #aaa);
}
.qtable_page_btn_group ul {
  display: flex;
  padding: 0px 15px 0px 15px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
.qtable_page_btn_group ul li a {
  color: var(--quantum-blue, #0078fe);
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.qtable_page_btn_group p {
  color: var(--black, #000);
  font-size: 14px;
  font-weight: 500;
}
.qtable_page_btn_group .dropdown_toggle {
  color: var(--grey-6, #aaa);
  font-size: 14px;
  font-weight: 500;
}

.qtable_page_btn_group_side {
  color: var(--grey-6, #aaa);
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.qtable_page_btn_group_num_active {
  color: var(--quantum-blue, #0078fe);
  font-size: 14px;
  font-weight: 700;
}

.qdatagrid_page_right {
  float: right;
}
.qdatagrid_page_right ul li a {
  font-size: 12px;
}

.qdatagrid_pagination_menu {
  transform: translate(10px, -25px) !important;
}

.qdatagrid_page_item_btn {
  display: inline-flex;
  margin-left: 10px;
  padding: 3px 5px 5px 10px;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 12px;
  border: none;
  background: var(--white, #fff);
  color: var(--grey-6, #aaa);
  font-size: 14px;
  font-weight: 500;
}
.qdatagrid_page_item_btn::after {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.19492 9.30095C8.08257 9.39724 7.91679 9.39724 7.80445 9.30095L3.65841 5.7472C3.44875 5.56749 3.1331 5.59177 2.95339 5.80143C2.77368 6.0111 2.79796 6.32675 3.00762 6.50646L7.15365 10.0602C7.64049 10.4775 8.35888 10.4775 8.84571 10.0602L12.9917 6.50646C13.2014 6.32675 13.2257 6.0111 13.046 5.80144C12.8663 5.59177 12.5506 5.56749 12.341 5.7472L8.19492 9.30095Z' fill='%23AAAAAA'/%3E%3C/svg%3E");
  border: none;
}
.qdatagrid_page_item_btn:hover {
  background-color: none;
}