.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  width: 100%;
  height: 100%;
}
.Container .Background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}
.Container .Modal {
  display: flex;
  flex-direction: column;
  z-index: 99999;
  min-width: 366px;
  max-width: 80%;
  max-height: calc(100% - 20px);
  min-height: 164px;
  background: white;
  border-radius: 0.5rem;
  padding: 1rem;
}
.Container .Modal .Header {
  display: flex;
  height: 16px;
  flex-direction: row;
  font-size: 1rem;
  font-weight: bold;
  align-items: center;
  margin-bottom: 1rem;
}
.Container .Modal .Body {
  display: flex;
  min-height: 44px;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #6c757d;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.Container .Modal .Body > div {
  width: 100%;
}
.Container .Modal .Footer {
  display: flex;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.Container .Modal .Footer .Button {
  padding: 0.5rem 1rem;
  height: 40px;
  border-radius: 0.5rem;
}