.Message {
  display: inline-block;
  font-size: 11.5px;
  font-weight: 500;
  text-align: center;
  border-radius: 1rem;
  padding: 4px 12px;
  margin: 0;
  max-width: 100%;
  color: var(--qt-gray7);
  background-color: var(--qt-gray3);
}
@media (min-width: 576px) {
  .Message {
    max-width: 80%;
  }
}